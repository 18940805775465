<template>
  <b-container class="overflow-auto" fluid>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <div class="cui__utils__heading mb-0">
              <strong>Keyword Lists</strong>
            </div>
          </div>
          <div class="card-body">
            <a-upload-dragger
              :action="kwListUploadAction"
              :headers="headers"
              :multiple="false"
              :beforeUpload="beforeUpload"
              :showUploadList=false
              name="File"
              @change="handleChange"
            >
              <p class="ant-upload-drag-icon">
                <a-icon type="inbox"/>
              </p>
              <p class="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p class="ant-upload-text-secondary">
                  Max. size: 2MB
              </p>
            </a-upload-dragger>
          </div>
        </div>
      </div>
    </div>

    <!-- Ad Interface controls -->
    <a-row>
      <a-checkbox v-model="showActive">
        Show only active
      </a-checkbox>
    </a-row>
    <b-row>
      <b-col class="my-1" md="6">
        <b-form-group class="mb-0" label="Per page:" label-cols-sm="2">
          <perPageSelect v-model="perPage" :storageKey="'xml-keywords'" :pageOptions="pageOptions" />
          <!-- <b-form-select v-model="perPage" :options="pageOptions" class="form-control ml-0 w-25"></b-form-select> -->
          <columnSelect :storageKey="'xml-keywords-columns'" :options="fields"></columnSelect>
        </b-form-group>
      </b-col>
      <b-col class="my-1 d-flex flex-row-reverse" md="6">
        <b-form-group class="mb-0">
          <b-input-group>
            <b-form-input v-model="filter" class="form-control w-50" placeholder="Type to Search"></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <!-- Main table element -->
    <b-table
      :bordered="true"
      :current-page="currentPage"
      :fields="filteredFields"
      :filter="filter"
      :hover="true"
      :items="showKwLists"
      :per-page="perPage"
      :small="true"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :striped="true"
      :tbody-tr-class="rowClass"
      show-empty
      stacked="md"
      @filtered="onFiltered"
    >
      <template #cell(StatusString)="data">
        <a-checkbox :checked="(data.item.Status == 0)" @change="toggleKwListStatus(data.item)">
        </a-checkbox>
      </template>
      <template #cell(actions)="data">
        <b-button
          class="btn-info mr-1"
          size="sm"
          @click.prevent="downloadKwList(data.item.Id, data.item.Name)"
        >
          <i class="fa fa-download"></i>>
        </b-button>
        <router-link class="add-button" @click.stop :to="{ name: 'kwlist-update', params: { id: data.item.Id }  }">
          <b-button
            class="btn-info mr-1"
            size="sm"
            @click="editKwList(data.item.Id)"
          >
            <i class="fa fa-edit"></i>
          </b-button>
        </router-link>
      </template>
      <template #cell(Name)="data">
        <div class="items-center">{{ data.item.Name }}</div>
      </template>
    </b-table>

    <b-row>
      <b-col class="my-1" md="6">
        <p>Showing {{ ((currentPage - 1) * perPage).toLocaleString() }} to {{
            (((currentPage - 1) * perPage) + perPage > totalRows ? totalRows : ((currentPage - 1) * perPage) + perPage).toLocaleString()
          }} of {{ parseInt(totalRows).toLocaleString() }} entries</p>
      </b-col>
      <b-col class="my-1 d-flex flex-row-reverse" md="6">
        <b-pagination
          v-model="currentPage"
          :per-page="perPage"
          :total-rows="totalRows"
          class="my-0"
        ></b-pagination>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'
import columnSelect from '@/components/custom/tableColumnSelect'
import perPageSelect from '@/components/custom/PerPageSelect'
import { getActionURL, downloadKwListFile } from '@/api/xml/kwlist'
import store from 'store'
import { Modal } from 'ant-design-vue'

export default {
  components: {
    columnSelect,
    perPageSelect,
  },
  data() {
    return {
      fields: [
        {
          key: 'actions',
          label: 'Actions',
          show: true,
          class: 'text-center',
          thStyle: 'width:90px',
        },
        {
          key: 'Id',
          label: 'ID',
          headerTitle: 'ID',
          show: true,
          sortable: true,
          thStyle: 'width:50px',
          tdClass: 'text-right',
        },
        {
          key: 'Name',
          label: 'Name',
          headerTitle: 'Keyword List Name',
          show: true,
          sortable: true,
        },
        {
          key: 'KeywordCount',
          label: 'Num. of Keywords',
          headerTitle: 'Num. of Keywords',
          formatter: '$formatNumber',
          show: true,
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-right',
          thStyle: 'width:160px',
        },
        {
          key: 'StatusString',
          label: 'Active',
          show: true,
          class: 'text-center',
          thStyle: 'width:80px',
        },
        // { key: 'Download', label: 'Download', show: true, class: 'text-center' },
      ],
      currentPage: 1,
      perPage: 50,
      pageOptions: [20, 50, 100, 200],
      sortBy: null,
      sortDesc: false,
      sortDirection: 'desc',
      filter: null,
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      kwListUploadAction: getActionURL(),
      headers: {
        Authorization: `Bearer ${store.get('accessToken')}`,
      },
    }
  },
  computed: {
    ...mapState(['kwlist', 'settings']),
    ...mapGetters('kwlist', ['getkwlistLists', 'getActiveKwLists']),
    showActive: {
      get: function () {
        return this.settings.showActiveKeywordLists
      },
      set: function (value) {
        this.$store.commit('CHANGE_SETTING', { setting: 'showActiveKeywordLists', value })
      },
    },
    showKwLists() {
      return this.showActive ? this.getActiveKwLists : this.getkwlistLists
    },
    filteredFields() {
      return this.fields.filter(c => c.show)
    },
    totalRows() {
      return this.showKwLists.length
    },
  },
  methods: {
    beforeUpload(file) {
      if (Math.round(file.size / 1024) <= 2048) {
        return true
      } else {
        Vue.prototype.$notification.error({
          message: 'File is too big',
          description: 'Maximum 2 MB allowed.',
        })
        return false
      }
    },
    downloadKwList(id, name) {
      downloadKwListFile(id, name)
    },
    rowClass(item, type) {
      if (item != null && item.Status === 1) return 'text-muted'
    },
    editKwList(id) {
      // this.$router.push('/xml/kwlist/update/' + id)
      this.$router.push({ name: 'kwlist-update', params: { id: id } }).catch(() => {})
    },
    toggleKwListStatus(kwlist) {
      const status = kwlist.Status === 0 ? 'Deactivate' : 'Activate'
      Modal.confirm({
        title: 'Are you sure you want to change Keyword list status?',
        content: h => <div>{status} Keyword list <strong>{kwlist.Name}</strong>?</div>,
        onOk: function () {
          const newStatus = kwlist.Status === 0 ? 1 : 0
          this.$store.dispatch('kwlist/CHANGE_STATUS', {
            id: kwlist.Id,
            status: newStatus,
          })
        }.bind(this),
      })
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    handleChange(info) {
      const status = info.file.status
      if (status === 'done') {
        this.$notification.success({
          message: 'Successfully Uploaded',
          description: `${info.file.name} file uploaded successfully.`,
        })
        this.$store.dispatch('kwlist/LOAD_KWLISTS')
      } else if (status === 'error') {
        this.$notification.error({
          message: 'Failed to upload',
          description: `${info.file.name} file upload failed.`,
        })
      }
    },
  },
  created() {
    this.$store.dispatch('kwlist/LOAD_KWLISTS')
  },
}
</script>

<style>
.ant-upload-text-secondary {
  font-size: 90%;
}
.ant-upload-drag-icon {
  margin-bottom: 15px !important;
}
</style>
